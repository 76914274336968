import React from "react";
import PropTypes from 'prop-types';

import { Button } from "react-bootstrap";
     
import { GetLanguageId, Translate } from "../Translator";
import { GetDatasourceName } from "../Datasources/Datasources"
import { ConfigurationApiIntegrationEnable, ConfigurationApiIntegrationSynchronizeNow } from "../../ExternalApi/ConfigurationApi.js"

import { GetDatasourceLive } from "../Datasources/Datasources.jsx";

import IntegrationWizardStepHeader from "../IntegrationWizard/IntegrationWizardStepHeader.jsx";
import MainIntegrationSyncInfo from "./MainIntegrationSyncInfo.jsx"

import NotificationTopPng from '../../Assets/NotificationTop.png';
import NotificationBottomPng from '../../Assets/NotificationBottom.png';

const MainIntegration = (props) => {

  const CurrentSynchronizedString = (recordCount, synchronizedTimeStamp) => {
    if ((synchronizedTimeStamp === null) || (synchronizedTimeStamp === ""))
    {
      return Translate("Not synchronized yet") 
    }
  
    let line = Translate("Current set") + ": " + recordCount + " " + Translate("phone number records")
  
    const msSince = Date.parse(synchronizedTimeStamp + "+0:00") //This is a utc time. Force it to be recognized as such
    const msNow = new Date() //Means now
  
    let diff = Math.floor((msNow - msSince) / 60000)
    if (diff < 1)
    {
      return line + " (just now)"
    }
    else if (diff === 1)
    {
      return line + " (" + diff + " " + Translate("minute ago") + ")"
    }
    else if (diff < 120)
    {
      return line + " (" + diff + " " + Translate("minutes ago") + ")"
    }
    else
    {
      diff = Math.floor(diff / 60)
      if (diff < 48)
      {
        return line + " (" + diff + " " + Translate("hours ago") + ")"
      }
      else
      {
        diff = Math.floor(diff / 24)
        return line + " (" + diff + " " + Translate("days ago") + ")"
      }
    }
  }

  const NextUpdateString = (nextUpdateTimeString) => 
  {
    if (nextUpdateTimeString)
    {
      let line = Translate("Automatic synchronization in") + " "

      const msUntil = Date.parse(nextUpdateTimeString + "+0:00") //This is a utc time. Force it to be recognized as such
      const msNow = new Date() //Means now

      let diff = Math.ceil((msUntil - msNow) / 60000)
      if (diff < 2)
      {
        return line + "1 " + Translate("minute")
      }
      else if (diff < 120)
      {
        return line + diff + " " + Translate("minutes")
      }
      else
      {
        diff = Math.ceil(diff / 60)
        if (diff < 48)
        {
          return line + diff + " " + Translate("hours")
        }
        else
        {
          diff = Math.ceil(diff / 24)
          return line + diff + " " + Translate("days")
        }
      } 
    }
    else
    {
      return Translate("Next synchronization could not be determined")
    } 
  }

  const ReplaceFields = (aLine) => {

    let bodyLine = aLine

    bodyLine = bodyLine.replace("#(Caller number)","0123456789")
    bodyLine = bodyLine.replace("#(Caller name)","John Doe")
    bodyLine = bodyLine.replace("#(Device number)","100")
    bodyLine = bodyLine.replace("#(Device name)","Employee 100")
    bodyLine = bodyLine.replace("#(Ddi number)","100")
    bodyLine = bodyLine.replace("#(Ddi name)","Ddi 100")
    bodyLine = bodyLine.replace("#(Application name)",props.Integration.ApplicationName)

    {props.Integration.DatasourceFields.map((field) => 
      bodyLine = bodyLine.replace("$(" + field.FieldLabel + ")",field.DefaultTestValue)
      )}      
    
    return bodyLine
  }

  const IntegrationEnable = async (integrationGuid, flag) => {
    if (props.SecurityToken !== "")
    {
      const newIntegrations = await ConfigurationApiIntegrationEnable(props.SecurityToken, integrationGuid, flag, props.UserName) 
      if (newIntegrations !== null) 
      {
        props.OnIntegrationsChanged(newIntegrations)
      }
      else
      {
        alert(Translate("Failed to update the integration"))
      } 
    }
    else
    {
      alert(Translate("Failed to update the integration"))
    }
  }

  const RefreshIntegrations = () => {
    props.OnIntegrationsRefresh()
  }

  const IntegrationSynchronizeNow = async (integrationGuid) => {
    if (props.SecurityToken !== "")
    {
      const newIntegrations = await ConfigurationApiIntegrationSynchronizeNow(props.SecurityToken, integrationGuid) 
      await ConfigurationApiCheckScheduler(props.SecurityToken, integrationGuid)
      if (newIntegrations !== null) 
      {
        props.OnIntegrationsChanged(newIntegrations)
      }
      else
      {
        alert(Translate("Failed to update the integration"))
      } 
    }
    else
    {
      alert(Translate("Failed to update the integration"))
    }
  }
  
  const BrandingColor = sessionStorage.getItem("BrandingColor")  
  const BrandingId = sessionStorage.getItem("BrandingId")
  const IsLive = GetDatasourceLive(props.Integration.DatasourceType)
  
  return (
    <span>
      <IntegrationWizardStepHeader Header={props.Integration.ApplicationName}/>

      <div className="surface namevalue">
        <table width="100%" cellPadding={16}>
        <tbody>
        {
          props.Integration.CreatorComputerName ?
            <tr>
              <td className="namevaluenamecolumn surfacedividerbottom" valign="center">{Translate("Source info")}</td>
              <td colSpan="2" className="surfacedividerbottom" valign="center">
                {Translate("Recognition from")} {GetDatasourceName(props.Integration.DatasourceType)}
                <span className="graytext">&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;{Translate("Integrations created with the desktop configurator can only be changed with the desktop configurator from the same computer.")}<br/>{Translate("Can be modified from computer") + " " + props.Integration.CreatorComputerName}</span>
              </td>
            </tr> 
            :
            <tr>
              <td className="namevaluenamecolumn surfacedividerbottom" valign="center">{Translate("Source info")}</td>
              <td className="surfacedividerbottom" valign="center">{Translate("Recognition from")} {GetDatasourceName(props.Integration.DatasourceType)}</td>
              <td className="surfacedividerbottom" valign="top"><Button variant="link" size="sm" className="mainconfigurebutton" onClick={() => props.OnOpenPopupFullscreen("/IntegrationWizard?Action=update&After=&SecurityToken=" + props.SecurityToken + "&languageid=" + GetLanguageId() + "&integrationguid=" + props.Integration.IntegrationGuid)}>{Translate("Configure")}</Button></td>
            </tr> 
        }
        <tr>
          <td width="200" className="namevaluenamecolumn surfacedividerbottom" valign="center">{Translate("Location info")}</td>
          <td width="*" className="surfacedividerbottom" valign="center">{Translate("Database country code")}: +{props.Integration.NumberSettings.CountryCode}</td>
          <td width="100" className="surfacedividerbottom" valign="top">
            <Button variant="link" size="sm" className="mainconfigurebutton" onClick={() => props.OnOpenPopupFullscreen("/IntegrationWizard?Action=setcountrycode&After=&SecurityToken=" + props.SecurityToken + "&languageid=" + GetLanguageId() + "&integrationguid=" + props.Integration.IntegrationGuid)}>{Translate("Configure")}</Button>  
          </td>
        </tr>
        <tr>
          <td className="namevaluenamecolumn surfacedividerbottom" valign="top">{Translate("Client call notification")}</td>
          <td className="surfacedividerbottom" valign="top">

            <table width="100%" cellPadding="0" cellSpacing="0">
            <tbody>
            <tr height="6" className="smallrow">
              <td width="533" height="6" colSpan={3}><img src={NotificationTopPng} style={{backgroundColor: BrandingColor}}/></td>
              <td width="*"/>
            </tr>
            <tr>
              <td width="6" style={{backgroundColor: BrandingColor}}></td>
              <td width="521" className="notificationpreview">
                  <div className="notificationpreviewheader surfacedividerbottom">{props.Integration.NotificationTemplate.Header}</div>
                  {props.Integration.NotificationTemplate.Body.map((line,index) => 
                    <div className="notificationpreviewbody surfacedividerbottom" key={index}>{ReplaceFields(line).trim() === "" ? <span className="graytext">{Translate("<empty line>")}</span> : ReplaceFields(line)}</div>
                  )}
              </td>
              <td width="6" style={{backgroundColor: BrandingColor}}></td>
              <td/>
            </tr>
            <tr height="64">
              <td colSpan="3"><img src={NotificationBottomPng} style={{backgroundColor: BrandingColor}}/></td>
              <td/>
            </tr>
            </tbody>
            </table> 

          </td>
          <td className="surfacedividerbottom" valign="top">
            <Button variant="link" size="sm" className="mainconfigurebutton" onClick={() => props.OnOpenPopupFullscreen("/IntegrationWizard?Action=setnotification&After=&SecurityToken=" + props.SecurityToken + "&languageid=" + GetLanguageId() + "&integrationguid=" + props.Integration.IntegrationGuid)}>{Translate("Configure")}</Button>  
          </td>
        </tr>
        <tr>
          <td className="namevaluenamecolumn surfacedividerbottom" valign="center">{Translate("Actions")}</td>
          <td className="surfacedividerbottom" valign="center">
          {props.Integration.Scripts?.map((script, i) => (
              <div key={i}>{script.Name}</div>
          ))}

          </td>
          <td className="surfacedividerbottom" valign="top">
            <Button variant="link" size="sm" className="mainconfigurebutton" onClick={() => props.OnOpenPopupFullscreen("/IntegrationWizard?Action=setscripts&After=&SecurityToken=" + props.SecurityToken + "&languageid=" + GetLanguageId() + "&integrationguid=" + props.Integration.IntegrationGuid)}>{Translate("Configure")}</Button>  
          </td>
        </tr>
        <tr>
          <td className="namevaluenamecolumn surfacedividerbottom" valign="top">{Translate("Synchronization info")}</td>
          <td className="surfacedividerbottom" valign="top">
            
            {props.Integration.Enabled && !IsLive ? <div>{CurrentSynchronizedString(props.Integration.RecordCount, props.Integration.SynchronizedTimeStamp)}</div> : ""}

            {!props.Integration.Enabled ? <b>{Translate("This integration is disabled")}</b> :
              (IsLive ? <div>{Translate("This application uses live search and does not need synchronization")}</div> : 
                <MainIntegrationSyncInfo SecurityToken={props.SecurityToken} IntegrationGuid={props.Integration.IntegrationGuid} AutoSync={(props.Integration.AutoUpdateFlags !== "") && (props.Integration.AutoUpdateFlags !== "none") && (props.Integration.AutoUpdateFlags !== "never")} NextSync={props.Integration.NextUpdate} LastSynced={props.Integration.SynchronizedTimeStamp} LastError={props.Integration.Enabled && !IsLive && props.Integration.LastUpdateRemark ? props.Integration.LastUpdateRemark : ""} OnIntegrationsRefresh={RefreshIntegrations}/>
            )}
          </td>
          <td className="surfacedividerbottom" valign="top">
            <Button variant="link" size="sm" className="mainconfigurebutton" onClick={() => IntegrationSynchronizeNow(props.Integration.IntegrationGuid)}>{Translate("Sync now")}</Button>
            <Button variant="link" size="sm" className="mainconfigurebutton" onClick={() => IntegrationEnable(props.Integration.IntegrationGuid, !props.Integration.Enabled)}>{props.Integration.Enabled ? Translate("Disable") : Translate("Enable")}</Button>  
          </td>
        </tr>
        <tr>
          <td/>
          <td/>
          <td colSpan={3} valign="top">
            <Button variant="dark" className={BrandingId === "enreach" ? "brandbuttonprimary" : "brandbuttonkeeporiginalstyle"} onClick={() => props.OnIntegrationDelete(props.Integration.IntegrationGuid)}>{Translate("Delete").toUpperCase()}</Button> 
          </td>
        </tr>      
        </tbody>
        </table>
      </div>
    </span>  
  )
}

MainIntegration.propTypes = {
  SecurityToken: PropTypes.string,
  UserName: PropTypes.string,
  Integration: PropTypes.any,
  History: PropTypes.any,
  OnOpenPopupFullscreen: PropTypes.func,
  OnIntegrationDelete: PropTypes.func,
  OnIntegrationsChanged: PropTypes.func,
  OnIntegrationsRefresh: PropTypes.func
}

export default MainIntegration